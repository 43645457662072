import axios from 'axios';
import { compact } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { LazyLoadComponent, LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { CHIP_LIST_LABELS, ChipList } from '../../components';
import PlaceholderImage from "../../slick/preloader.gif";
import { actionUrlConverter, hostUrlConverter, imgUrlConverter } from '../../util';
import { PriceComponent } from './price';
import { ProductTilesWrapper } from './productTilesWrapper';
import SeeDetailsComponent from './seeDetails';
import { Spinner } from './spinner';
import { promoDataConverter } from './utils';

/**
 *
 * @param {array} promoCodes list coming from the DotCms
 * @param {array} schoolStoreEvents list of all events available for the customer ( Order Day, Class Meetings and other events )
 * @param {boolean} isSliderView value to transform the products list into a slider
 * 
 */

export const ProductBrowseTiles = trackWindowScroll(React.memo(({
  pbData,
  fromProchamp,
  fromHsJwl,
  pageContent,
  fromHsGrad,
  paymentOptions,
  scrollPosition,
  productInfo,
  isColJlry,
  promoCodes,
  isDiploma,
  isColRegalia,
  fromColGradAnnc,
  isCustomerInExcludeList,
  schoolStoreEvents,
  isSliderView = false
}) => {
  const [seeDetails, setSeeDetails] = useState(false);
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([])
  const [soldOutData, setSoldOutData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [lazyItems, setLazyItems] = useState([]);
  const [isLazyItemsSet, setIsLazyItemSet] = useState(false);

  const handleScroll = () => {
    fetchData();
  };

  const fetchData = () => {
    if (fromProchamp || isDiploma || isColRegalia) {
      removeScrollEvent();
    } else {
      setLazyItems(productList ? [...productList.slice()] : []);
      setIsLazyItemSet(true);
      removeScrollEvent();
    }
  }

  const addScrollEvent = () => {
    window.addEventListener('scroll', handleScroll);
  }

  const removeScrollEvent = () => {
    window.removeEventListener('scroll', handleScroll);
  }

  useEffect(() => {
    if (window?.document?.body?.clientWidth < 1350) {
      addScrollEvent();
      isSliderView && setLazyItems(productList ? [...productList.slice()] : []);
    } else {
      setLazyItems(productList ? [...productList.slice()] : []);
      setIsLazyItemSet(true);
    }
    return () => removeScrollEvent();
  }, [isLoading, isSliderView])

  useEffect(() => {
    const url = '/apps/store/api/v1.1/soldout-products'
    axios.get(url,
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(({ data }) => {
        if (Array.isArray(data) && data.length) {
          const soldOutProductData = data.reduce((acc, product) => {
            if (product.productOsr) {
              acc[product.productOsr] = product
            }
            return acc
          }, {});

          setSoldOutData(soldOutProductData);
        }
      })
  }, [])

  useEffect(() => {
    if (!pbData)  return;

    let performSort = productInfo?.hasOwnProperty('performSort') ? productInfo?.performSort : true
    if (performSort) {
      pbData?.products?.sort((a, b) => {
        if (a.orderSeq && b.orderSeq) {
          if (a.orderSeq === b.orderSeq) {
            return a.displayName > b.displayName ? 1 : -1
          }
          return a.orderSeq - b.orderSeq
        } else if (a.orderSeq) {
          return -1
        } else {
          return 1
        }
      });
    }

    let salesProductData = pbData.products?.map((productObj) => {
      promoDataConverter({
        productObj,
        promoCodes,
        fromHsJwl,
        isCustomerInExcludeList,
        schoolStoreEvents
      })

      return productObj
    })
    setProductList(salesProductData);
    if (fromProchamp || isDiploma || isColRegalia) {
      salesProductData && setLazyItems(salesProductData);
    } else {
      if (isLoading) {
        salesProductData && salesProductData.length > 0 && setIsLoading(false);
      } else {
        isLazyItemsSet && setLazyItems(salesProductData);
      }
    }

  }, [pbData.products, fromHsJwl])

  const viewDetails = useCallback(
    (data) => {
      setSeeDetails(true)
      setProductData({ ...data, "showMetalOption": !pageContent?.hideMetalOption })
    },
    [],
  )
  const nextIcon = () => {
    return <div className="swiper-btn-next"><i className="far fa-arrow-right" role="img" aria-label='arrow-right'></i></div>
  }
  const prevIcon = () => {
    return <div className="swiper-btn-prev"><i className="far fa-arrow-left" role="img" aria-label='arrow-left'></i></div>
  }
  const navigate = (productObj) => {
    setLoading(true);
    let redirectUri = fromProchamp ? actionUrlConverter(productObj?.actionUrl) : hostUrlConverter(productObj?.actionUrl)
    window.location.assign(redirectUri);
  }

  const checkExcludedSeeDetailsOsr = (productObj) => {
    if (pageContent && Object.keys(pageContent)?.length > 0 && pageContent.ctaCheck?.excludedSeeDetailsProductOsrs?.split(",")?.length > 0) {
      return !(pageContent.ctaCheck?.excludedSeeDetailsProductOsrs.split(",").find((osrValue) => osrValue === productObj.productOsr))
    } else {
      return true
    }
  }

  const carouselHovered = (key) => {
    document.getElementById("hovered-image-" + key).style.display = "none";
    document.getElementById("placeholder-image-" + key).style.display = !isSliderView ? "block": "flex";
  }

  const carouselLeft = (key) => {
    document.getElementById("hovered-image-" + key).style.display = !isSliderView ? "block": "flex";
    document.getElementById("placeholder-image-" + key).style.display = "none";
  }

  const pageContentTitle = pageContent?.title && (
    <>
      <div className="product-title">
        <span
          className="px-2 pt-2 h5"
          dangerouslySetInnerHTML={{ __html: pageContent.title }}
        />
      </div>
      <div className='container-fluid'><hr /></div>
    </>
  )

  if (pbData) {
    return (
      <>
        {pageContentTitle}
        {loading && <Spinner />}
        {lazyItems.length > 0 && (
          <ProductTilesWrapper isSliderView={isSliderView}>
            {lazyItems?.map((productObj, key) => {
              let altImages = [];
              if (productObj?.altImages?.length > 0) {
                altImages = productObj?.altImages?.filter(obj => obj.isAlternate === true).sort((a, b) => a.orderSeq - b.orderSeq);
                productObj?.altImages[0].isAlternate === false && altImages.push(productObj?.altImages[0])
              }

              const isLimitedSizeProduct = soldOutData[productObj?.prodRefCode] && soldOutData[productObj?.prodRefCode]?.featureOsr
              const isSoldOutProduct = soldOutData[productObj?.prodRefCode] && !soldOutData[productObj?.prodRefCode]?.featureOsr
              const shouldChipListDisplay = productObj?.messageWithPromoCode || isLimitedSizeProduct || isSoldOutProduct

              const chipsItems = compact([
                productObj?.messageWithPromoCode && CHIP_LIST_LABELS.SALE,
                isLimitedSizeProduct && CHIP_LIST_LABELS.LIMITED_SIZE,
                isSoldOutProduct && CHIP_LIST_LABELS.SOLD_OUT
              ])

              return (
                <div className="product-container mobile-center" key={"pc" + key} title={productObj?.displayName}>
                  <div
                    id={"hovered-image-" + key}
                    className="cursorPointer"
                    role="img"
                    aria-label={productObj?.displayName}
                    onClick={() => navigate(productObj)}
                  >
                    {
                      shouldChipListDisplay && !isCustomerInExcludeList && <ChipList chipItemsData={chipsItems} />
                    }
                    <LazyLoadComponent>
                      <picture>
                        <source
                          media="(min-width: 1920px)"
                          srcSet={imgUrlConverter(productObj?.imageUrl, pageContent?.ctaCheck?.desktopImageVariant)}
                        />
                        <LazyLoadImage
                          src={imgUrlConverter(productObj?.imageUrl || pageContent?.imageUrl, pageContent?.ctaCheck?.mobileImageVariant)}
                          alt={productObj?.displayName}
                          scrollPosition={scrollPosition}
                          effect="blur"
                          onError={(e) => e.target.src = pageContent?.imageUrl}
                        />
                      </picture>
                    </LazyLoadComponent>
                  </div>
                  <div
                    id={"placeholder-image-" + key}
                    className="cursorPointer"
                    style={{ display: "none" }}
                    role="img"
                    aria-label={`placehoder-image-${key}`}
                  >
                    {!fromProchamp && (<picture>
                      <source media="(min-width: 1920px)" srcSet={PlaceholderImage} />
                      <img src={PlaceholderImage} alt={"placehoder-image"} />
                    </picture>)}
                  </div>
                  {
                    altImages?.length > 0 &&
                    <LazyLoadComponent>
                      <Carousel
                        onMouseLeave={() => carouselLeft(key)}
                        onMouseEnter={() => carouselHovered(key)}
                        className="product-button"
                        nextIcon={altImages?.length > 1 ? nextIcon() : null}
                        prevIcon={altImages?.length > 1 ? prevIcon() : null}
                        indicators={false}
                        indicatorlabels={[]}
                        interval={null}
                        key={"slider" + key}
                      >
                        {
                          altImages?.map((altImage) =>
                            <Carousel.Item key={altImage?.altImageDisplayName + key}>
                              <div
                                className="cursorPointer"
                                role="img"
                                aria-label={`${altImage?.altImageDisplayName}`}
                                onClick={() => navigate(productObj)}
                              >
                                <div>
                                  <LazyLoadComponent>
                                    <picture>
                                      <source
                                        media="(min-width: 1920px)"
                                        srcSet={imgUrlConverter(altImage?.altImageUrl, pageContent?.ctaCheck?.desktopImageVariant)}
                                      />
                                      <LazyLoadImage
                                        src={imgUrlConverter(altImage?.altImageUrl || pageContent?.imageUrl, pageContent?.ctaCheck?.mobileImageVariant)}
                                        alt={altImage?.altImageDisplayName} scrollPosition={scrollPosition}
                                        effect="blur"
                                        onError={(e) => e.target.src = pageContent?.imageUrl} />
                                    </picture>
                                  </LazyLoadComponent>
                                </div>
                              </div>
                            </Carousel.Item>
                          )
                        }
                      </Carousel>
                    </LazyLoadComponent>
                  }
                  {checkExcludedSeeDetailsOsr(productObj) &&
                    <div className="carousel-caption">
                      <button className="product-button-btn" onClick={() => viewDetails(productObj)}> SEE DETAILS </button>
                    </div>
                  }
                  <div className="fa-xs mt-2 priceContainer">
                    <div onClick={() => setLoading(true)}>
                      <a href={fromProchamp ? actionUrlConverter(productObj?.actionUrl) : hostUrlConverter(productObj?.actionUrl)} rel="nofollow">
                        <div className="cursorPointer product-name-font">{productObj?.displayName}</div>
                      </a>
                    </div>
                    {!isSoldOutProduct && productObj && Object.keys(productObj)?.length > 0 && (
                      <PriceComponent
                        data={productObj}
                        pageContent={pageContent}
                        fromHsJwl={fromHsJwl}
                        isColJlry={isColJlry}
                        isColRegalia={isColRegalia}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </ProductTilesWrapper>
        )}
        {seeDetails && (
          <SeeDetailsComponent
            setSeeDetails={setSeeDetails}
            productData={productData}
            fromProchamp={fromProchamp}
            fromHsJwl={fromHsJwl}
            fromHsGrad={fromHsGrad}
            isColJlry={isColJlry}
            isColRegalia={isColRegalia}
            paymentOptions={paymentOptions}
            productInfo={productInfo}
            pageContent={pageContent}
            soldOutData={soldOutData}
            fromColGradAnnc={fromColGradAnnc}
            isCustomerInExcludeList={isCustomerInExcludeList}
            promoCodes={promoCodes}
            schoolStoreEvents={schoolStoreEvents}
          />
        )}
      </>
    )
  }
}))
