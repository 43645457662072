import { useEffect, useState } from 'react'
import { getCustomerNumber } from '../util'

export const useGetConsumerDataById = (consumerId) => {
    const [loading, setLoading] = useState(true)
    const [consumerData, setConsumerData] = useState()
    
    useEffect(() => {
        (async () => {
            const customerNumber = getCustomerNumber()
            try {
                const  url= `${window.location.origin}/apps/store/api/v1.1/customers/${customerNumber}/namelist-verification/${consumerId}`
                const response = await fetch(url, { signal: AbortSignal.timeout(5000) })
                const data = await response.json()

                if (data && Object.keys(data)?.length > 0) {
                    setConsumerData(data)
                }
            } catch (error) {
                console.log(`Failed to get consumer data by id [${consumerId}]: `, error)
            } finally {
                setLoading(false);
            }
        })()
    },[])

    return { consumerData, consumerDataLoading: loading } 
}