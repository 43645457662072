export const isValidUrl = (url) => {
    try {
        if (url?.indexOf("http://") === 0 || url?.indexOf("https://") === 0) {
            return new URL(url)
        }
        else{
            return new URL(url, window.location.protocol + window.location.hostname)
        }
    } catch (e) {
      console.error(e)
      return false
    }
    // return true
}

export const domainConverter = (iUrl) => {
    if (iUrl) {
        if (isValidUrl(iUrl)) {
            const url = new URL(iUrl)
            let host = url.hostname
            switch (window.location.hostname) {
                case 'localhost':
                case 'devwww.jostens.com':
                case 'dev1cms.jostens.com':
                    host = 'devwww.jostens.com'
                    break

                case 'testwww.jostens.com':
                    host = 'testwww.jostens.com'
                    break

                case 'demowww.jostens.com':
                    host = 'democdn.jostens.com'
                    break

                case 'qawww.jostens.com':
                    host = 'qacdn.jostens.com'
                    break

                case 'www.jostens.com':
                    host = 'cdn.jostens.com'
                    break

                default:
                    break;
            }
            return 'https://' + host + url.pathname + url.search + url.hash
        } else {
            return iUrl
        }
    }
    return null
}

export const imgUrlConverter = (iUrl, variantOption) => {
    if (iUrl) {
        let url = isValidUrl(iUrl)
        if(window.domainVariables){
            let {imageDomain} = window.domainVariables
            return imageDomain + url.pathname + url.search + url.hash
        }
        if(variantOption){
            url.search = url.search?.replace(/variant=[a-zA-Z_]+/, `variant=${variantOption}`)
        }
        if (url) {
            let host = url.hostname
            switch (window.location.hostname) {
                case 'localhost':
                case 'devwww.jostens.com':
                case 'dev1cms.jostens.com':
                case 'dev-jostens.dotcmscloud.com':
                case 'dev1cms-prochamp.jostens.com':
                case 'devwww.prochamp.jostens.com':
                case 'jostens-dev.dotcms.cloud':
                    host = 'devwww.jostens.com'
                    break
                
                case 'testwww.jostens.com':
                case 'test-jostens.dotcmscloud.com':
                case 'testwww.prochamp.jostens.com':
                    host = 'testwww.jostens.com'
                    break
            
                case 'demowww.jostens.com':
                case 'demo-jostens.dotcmscloud.com':
                case 'demowww.prochamp.jostens.com':
                case 'uatcms-prochamp.jostens.com':
                case 'jostens-uat.dotcms.cloud':
                    host = 'democdn.jostens.com'
                    break
            
                case 'qawww.jostens.com':
                case 'qa-jostens.dotcmscloud.com':
                case 'qawww.prochamp.jostens.com':
                case 'jostens-auth.dotcms.cloud':
                    host = 'qacdn.jostens.com'
                    break
    
                case 'www.jostens.com':
                case 'www.prochamp.jostens.com':
                case 'jostens-prod.dotcms.cloud':
                    host = 'cdn.jostens.com'
                    break
    
                default:
                    break;
            }
            return url.protocol + '//' + host + url.pathname + url.search + url.hash
        } else {
            return iUrl
        }
    }
    return null
}

export const alternateUrlConverter = (aUrl) => {
    if (isValidUrl(aUrl)) { 
        return aUrl
    } else {
        return 'https://www.jostens.com' + aUrl
    }
}

export const actionUrlConverter = (aUrl) => {
    let host
    if (window.location.hostname === 'dev1cms.jostens.com' || window.location.hostname === 'localhost' 
        || window.location.hostname === 'dev-jostens.dotcmscloud.com' || window.location.hostname === 'dev1cms-prochamp.jostens.com'
        || window.location.hostname === 'devwww.prochamp.jostens.com') {
        host = 'devwww.prochamp.jostens.com'
    } else if (window.location.hostname === 'uatcms.jostens.com') {
        host = 'demowww.prochamp.jostens.com'
    }else if (window.location.hostname === 'authcms.jostens.com') {
        host = 'www.prochamp.jostens.com'
    } else {
        host = window.location.hostname
    }

    const url = isValidUrl(aUrl)
    if (url) {
        return window.location.protocol + '//' + host + url.pathname + url.search + url.hash
    } else {
        return window.location.protocol + '//' + host + aUrl
    }
}

export const hostUrlConverter = (aUrl) => {
    let host
    if (window.location.hostname === 'dev1cms.jostens.com' || window.location.hostname === 'localhost' || window.location.hostname === 'dev-jostens.dotcmscloud.com' 
        || window.location.hostname === 'devwww.prochamp.jostens.com') {
        host = 'devwww.jostens.com'
    } else if (window.location.hostname === 'uatcms.jostens.com' || window.location.hostname === 'demowww.prochamp.jostens.com') {
        host = 'demowww.jostens.com'
    } else if (window.location.hostname === 'qawww.prochamp.jostens.com') {
        host = 'qawww.jostens.com'
    } else if (window.location.hostname === 'authcms.jostens.com' || window.location.hostname === 'www.prochamp.jostens.com') {
        host = 'www.jostens.com'
    } else {
        host = window.location.hostname
    }

    const url = isValidUrl(aUrl)
    if (url) {
        return window.location.protocol + '//' + host + url.pathname + url.search + url.hash
    } else {
        return window.location.protocol + '//' + host + aUrl
    }
}

export const heroBannerEvent = (events) => {
    return events.filter( e => {
                const dateRange = e.dateRange.split(' - ')
                const endDay = new Date(dateRange[1])
                const today = new Date();
                const days = Math.ceil((endDay.getTime() - today.getTime()) / (1000 * 3600 * 24))
                return days >= 0 && days <= 14 && e.runningTopEvent === true && e.schedulingUrl
            })[0]
}

export const daysBetweenToday = (d) => {
    const today = new Date();
    return Math.ceil((d.getTime() - today.getTime()) / (1000 * 3600 * 24))
}

/**
* Error logger to track braking exceptions and optionally redirect to error page
* @param {string} message - error message
* @param {string} rc - reason code for quick error tracking
* @param {boolean} shouldRedirect - should redirect to error page
*/
export const errorLogger = async (message, rc, shouldRedirect = true) => {
    if (window.location.hostname === "localhost") {
        console.error("Error:" + message);
        return;
    }

    try {
        const options = {
            customerNumber: getCustomerNumber(),
            appCustomer: "product-browse-ui",
            src: window.location.href,
            occTimestamp: new Date().toString(),
            appEnvironment: navigator.userAgent,
            faultDetails: message
        };

        await fetch('/apps/store/bummer.mvc', {
            method: 'POST',
            body: JSON.stringify(options)
        })

        if (shouldRedirect) {
            window.location.href = `/apps/jcom/router.mvc?error=true&errorGroupName=${rc}`;
        }

    } catch (error) {
        console.error("Unable to call bummer.mvc for 404 logging:" + error);
    }
}

export const checkURLHasDomain = (url) => {
    return !!(url.indexOf("http://") === 0 || url.indexOf("https://") === 0)
}

export let packageProductsSliderSettings = {
    accessibility: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    dots: false,
    variableWidth: false,
    adaptiveHeight: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                variableWidth: false
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: false,
                arrows: true,
                variableWidth: false
            }
        }
    ]
}

export const setCookie = (name, value, days = 7, path = '/') => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`
}
  
export const getCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
}

/**
* Get customer number by parsing query string parameters
* @returns {string} path name parameter at 4th index
*/
export const getCustomerNumber = () => {
    const pathNameParts = window.location.pathname.split('/')

    return pathNameParts && pathNameParts[4]
}
