import Slider from "react-slick";

import { settings, container } from './constants';

export const ProductTilesWrapper = ({ children, isSliderView }) => isSliderView ? (
    <div className="product-categories">
        <div className="container-fluid">
            <Slider {...settings} className="carousel">
                {children}
            </Slider>
        </div>
    </div>
) : (
    <div className="product-grid-inner-container container-fluid" >
        {children}
    </div >
)