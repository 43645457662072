import { useEffect, useState } from 'react'
import { loadRegaliaUxParamsFromUrlHashtags, redirectWithHashtagParams, selectFilterState } from './util'
import { AppModal } from "../basic/app-modal"
import { JostensLogo } from "../basic/jostens-logo"
import { Spinner, Background } from '../basic/loader'
import { RegaliaUxConstants } from './constants'
import { useGetCustomerNameListSettings } from './hooks'
import { 
    RegaliaUxConsumerTypeStep, 
    RegaliaUxCatalogStep, 
    RegaliaUxDegreeStep, 
    RegaliaUxStudentVerificationStep,
} from './step-components'

/**
 * Orchestration component for the new Regalia UX UI.
 * @param data input service data
 * @param isVisible controls the visibility of the component
 * @param onHide callback function to be triggered when the dialog closes
 * @param onSelectCatalog callback function to be triggered when a catalog is selected
 * @param onSelectDegree callback function to be triggered when a degree is selected
 */
export const RegaliaUxDialog = ({data, isVisible, onHide, onSelectCatalog, onSelectDegree}) => {
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(RegaliaUxConstants.CONSUMER_TYPE_STEP);
    const [consumerSelection, setConsumerSelection] = useState({
        catalogOsr: undefined,
        publishedCatalogId: undefined,
        degree: undefined,
    });

    const { customerNameListSettings, customerNameListSettingsLoading } = useGetCustomerNameListSettings()

    const isStudentVerificationProgramAvailable = () => {
        return customerNameListSettings && customerNameListSettings.length > 0;
    }

    const hasConsumerTypeSVPSettings = (catalogOsr) => {
        
        const isStudentConsumerType = RegaliaUxConstants.STUDENT_CATALOG_OSR_LIST.includes(catalogOsr)
        const hasStudentNameListSettings = customerNameListSettings.some(el=> el.verificationType==='STUDENT')
        const isFacultyConsumerType = RegaliaUxConstants.FACULTY_CATALOG_OSR_LIST.includes(catalogOsr)
        const hasFacultyNameListSettings = customerNameListSettings.some(el=> el.verificationType==='FACULTY')
        
        if (isStudentConsumerType && hasStudentNameListSettings) return true
        if (isFacultyConsumerType && hasFacultyNameListSettings ) return true;
        
    };

    const setConsumerTypeSelection = (catalogOsr) => {
        setConsumerSelection({...consumerSelection, catalogOsr})

        if (isStudentVerificationProgramAvailable() && hasConsumerTypeSVPSettings(catalogOsr)) {
            // Show the Student Verification dialog
            setActiveStep(RegaliaUxConstants.STUDENT_VERIFICATION_STEP)
        } else {
            // skip the dialog Catalog if it has only one option and preselect the available value
            const catalogSelection = data.stepsData[RegaliaUxConstants.CATALOG_STEP][catalogOsr]
            if (catalogSelection?.length === 1) {
                setCatalogSelection(catalogOsr, catalogSelection[0].value)
            } else {
                setActiveStep(RegaliaUxConstants.CATALOG_STEP)
            }
        }
    }

    const setCatalogSelection = (catalogOsr, publishedCatalogId) => {
        const currentSelection = {...consumerSelection, catalogOsr, publishedCatalogId}
        setConsumerSelection(currentSelection)
        if (data?.catalogActionUrl) {
            setLoading(true)
            redirectWithHashtagParams(data.catalogActionUrl, currentSelection)
        } else {
            const catalogSelection = data.stepsData[RegaliaUxConstants.CATALOG_STEP][catalogOsr]
            const selection = catalogSelection?.find(el => el.value === publishedCatalogId)
            onSelectCatalog && selection && onSelectCatalog(selection)
            
            const shouldDisplayDegreeStep = !isStudentVerificationProgramAvailable()
            if (shouldDisplayDegreeStep) {
                setActiveStep(RegaliaUxConstants.DEGREE_STEP)
            } else {
                onHide()
            }
        }
    }

    const setDegreeSelection = (catalogOsr, publishedCatalogId, degreeEventData) => {
        const { selectedFilterOsr, updatedFilterData } = degreeEventData
        const currentSelection = {
            ...consumerSelection, 
            catalogOsr, 
            publishedCatalogId, 
            degree: selectedFilterOsr
        }
        setConsumerSelection(currentSelection)
        onSelectDegree && onSelectDegree(updatedFilterData)
    }

    const onHideHandler = () => {
        // reset active step index before close dialog
        setActiveStep(RegaliaUxConstants.CONSUMER_TYPE_STEP)
        loadConsumerSelectionFromHashtagParams()
        setLoading(false)
        onHide && onHide()
    }


    /**
     * This function loads state and returns informations taken from the hashtag portion of the URL.
     * @return JSON object containing catalogOsr, publishedCatalogId and degree.
     */
    const loadConsumerSelectionFromHashtagParams = () => {
        const loadedParams = loadRegaliaUxParamsFromUrlHashtags()
        setConsumerSelection(loadedParams)
        if (loadedParams.publishedCatalogId && loadedParams.catalogOsr) { 
            setCatalogSelection(loadedParams.catalogOsr, loadedParams.publishedCatalogId)            
            /* TODO: DRAFT not working - review it
            if (loadedParams.degree) {
                const updatedFilterData = selectFilterState(data.filterData.filterGroups, RegaliaUxConstants.DEGREE_FILTER_GROUP_OSR, loadedParams.degree)
                setDegreeSelection(loadedParams.catalogOsr, loadedParams.publishedCatalogId, { selectedFilterOsr: loadedParams.degree, updatedFilterData })            
            }*/
        } else if (loadedParams.catalogOsr) { 
            setActiveStep(RegaliaUxConstants.CATALOG_STEP)
            setConsumerSelection(loadedParams) 
        } else {
            setActiveStep(RegaliaUxConstants.CONSUMER_TYPE_STEP)
            setConsumerSelection(loadedParams) 
        }
        return loadedParams
    }

    const regaliaUxSteps = {
        [RegaliaUxConstants.CONSUMER_TYPE_STEP]: <RegaliaUxConsumerTypeStep {...data} onClick={(e) => setConsumerTypeSelection(e.target.value)} />,
        [RegaliaUxConstants.CATALOG_STEP]: <RegaliaUxCatalogStep {...data} consumerSelection={consumerSelection} onClick={(e) => setCatalogSelection(consumerSelection.catalogOsr, e.target.value)} />,
        [RegaliaUxConstants.DEGREE_STEP]: <RegaliaUxDegreeStep {...data} consumerSelection={consumerSelection} onClick={degreeEventData => setDegreeSelection(consumerSelection.catalogOsr, consumerSelection.publishedCatalogId, degreeEventData)} />,
        [RegaliaUxConstants.STUDENT_VERIFICATION_STEP]: <RegaliaUxStudentVerificationStep {...data} setCatalogSelection={setCatalogSelection} consumerSelection={consumerSelection} customerNameListSettings={customerNameListSettings} loading={customerNameListSettingsLoading} />
    }

    useEffect(() => {
        const areAllParamsEmpty = Object.values(consumerSelection).every(el => !el)
        if (data && isVisible && areAllParamsEmpty && !customerNameListSettingsLoading) {
            const loadedParams = loadConsumerSelectionFromHashtagParams()
            setLoading(false)
            
            // skip the dialog Consumer Type if it has only one option and preselect the available value
            if ((activeStep === RegaliaUxConstants.CONSUMER_TYPE_STEP) 
                    && (data.stepsData[RegaliaUxConstants.CONSUMER_TYPE_STEP].length === 1)
                    && !(loadedParams.catalogOsr)
                ) {
                setConsumerTypeSelection(data.stepsData[RegaliaUxConstants.CONSUMER_TYPE_STEP][0])
            }
        }
    },[data, isVisible, customerNameListSettingsLoading])


    return isVisible && (
        <AppModal className="regalia-ux-dialog" visible={true} onHide={onHideHandler} headerContent={<JostensLogo />} >
            {loading && (
                <Background>
                    <Spinner />
                </Background>
            )}
            <div>{regaliaUxSteps[activeStep]}</div>
        </AppModal>
    )
}