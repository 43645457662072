import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AlertsList } from '../../components/Alerts';
import {
	BREADCRUMB,
	FEATUREDPRODUCTS,
	FILTERGROUPTILES,
	HELPBUTTON,
	PAGETITLE,
	PAYPALBANNER,
	PRODUCTTILES,
	PROMOMSG,
} from '../../constants/rootElements';
import { gaPageLoad } from '../../util/googleAnalytics';
import { Breadcrumb } from '../breadcrumb';
import { HelpButton } from '../help';
import { FeaturedProducts } from '../hs-jwl/featuredProducts';
import { PaypalBanner } from '../paypalBanner';
import { TitleBar } from '../productBrowseTitle';
import { ColJlryProductTiles } from './colJlryProductTiles';
import { FilterGroupsTiles } from './filterGroupTiles';

export const ColJWLPage = ({ appData, isCanadaCollege }) => {
	const [breadcrumbData, setBreadcrupmData] = useState([]);
	const [promoMessages, setPromoMessages] = useState([]);
	const [pbData, setPbData] = useState({});
	const [catalogInfo, setCatalogInfo] = useState({hasOrp:false, hasStandard: false, hasJlry: false, hasNationalCat: false, showFeatured:false, hasOnlyOrpCollection:false, isCanadaCollege});
	const [serviceData, setServiceData] = useState({});
	const [filterGroupData, setFilterGroupData] = useState([]);
	const [filterData, setFilterData] = useState({filter:{},isOrpFilter:false});
	const [selectedSubCatalog, setSelectedSubCatalog] = useState({});
	const [filterGroupTilesData, setFilterGroupTilesData] = useState([]);
	const [productGroupProducts, setProductGroupProducts] = useState([])
	const [topFilterClicked, setTopFilterClicked] = useState(false)
	const [selectedGroup, setSelectedGroup] = useState("");
	const [clickedFilterImageUrl, setClickedFilterImageUrl] = useState("");
	const [schoolStoreEvents, setSchoolStoreEvents] = useState([]);

	const ColJlryBreadcrumb = Boolean(
		appData?.pagecontentData?.find((data) => data.contentType === 'Navigation')
	);

	const ColJlryTitleBar = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseTitle'
	);

	const ProductBrowsePromoMsg = Boolean(
		appData?.pagecontentData?.find(
			(data) => data.contentType === 'ProductBrowsePromoMsg'
		)
	);

	const PaypalBannerData = appData?.pagecontentData?.find(
		(data) => data.contentType === 'PaypalId'
	);

	const ProductBrowseFiltersGroups = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseFiltersGroups'
	);

	const ProductBrowseFeatured = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseFeatured'
	);

	const ProductBrowseProducts = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseProducts'
	);

	const PaymentOptions = appData?.pagecontentData?.find(
		(data) => data.contentType === 'PaymentOptions'
	);

	const getBreadcrumb = useCallback(() => {
		BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
	}, [breadcrumbData]);

	const getTitleBar = useCallback(() => {
		PAGETITLE.render(
			<TitleBar
				pageContent={ColJlryTitleBar}
				affiliationData={appData?.affiliationData}
			/>
		);
	}, [ColJlryTitleBar]);

	const getPromoMsg = useCallback(() => {
		PROMOMSG.render(<AlertsList messages={promoMessages} />);
	}, [promoMessages]);

	const filterGroupTiles = useCallback(() => {
		FILTERGROUPTILES.render(
			<FilterGroupsTiles
				getProducts={getProducts}
				serviceData={serviceData}
				filterGroupData={filterGroupTilesData}
				pageContentFilterGroup={ProductBrowseFiltersGroups}
				catalogInfo={catalogInfo}
				altImage={ProductBrowseProducts?.imageUrl}
			/>
		);
	}, [
		filterData,
		serviceData,
		ProductBrowseFiltersGroups,
		catalogInfo
	]);

	const paypalBanner = useCallback(() => {
		PAYPALBANNER.render(<PaypalBanner paypalBannerData={PaypalBannerData} />);
	}, [PaypalBannerData]);

	const featuredProducts = useCallback(() => {
		if(ProductBrowseFeatured && ProductBrowseProducts && ProductBrowseProducts["ctaCheck"] && ProductBrowseFeatured["ctaCheck"]) {
			ProductBrowseFeatured["ctaCheck"] = {...ProductBrowseFeatured["ctaCheck"], ...ProductBrowseProducts["ctaCheck"]}
		}
		FEATUREDPRODUCTS.render(
			<FeaturedProducts
				pbData={{
					products: [
						...getOrpCatalogProducts.orpProducts,
						...getNationalCatalogProducts,
					],
				}}
				paymentOptions={PaymentOptions}
				pageContentFeatured={ProductBrowseFeatured}
				isColJlry={true}
				altImage={ProductBrowseProducts?.imageUrl}
				schoolStoreEvents={schoolStoreEvents}
			/>
		);
	}, [serviceData, ProductBrowseFeatured]);

	const productTiles = useCallback(() => {
		PRODUCTTILES.render(
			<ColJlryProductTiles
				pbData={pbData}
				pageContent={ProductBrowseProducts}
				filterData={filterData.filter}
				selectedSubCatalog={selectedSubCatalog}
				PaymentOptions={PaymentOptions}
				isOrpFilter={filterData.isOrpFilter}
				topFilterClicked={topFilterClicked}
				setTopFilterClicked={setTopFilterClicked}
				selectedGroup={selectedGroup}
				setSelectedGroup={setSelectedGroup}
				filterGroupTilesData={filterGroupTilesData}
				clickedFilterImageUrl={clickedFilterImageUrl}
				getProducts={getProducts}
				schoolStoreEvents={schoolStoreEvents}
			/>
		);
	}, [
		pbData,
		ProductBrowseProducts,
		filterData,
		PaymentOptions,
		topFilterClicked,
		selectedGroup
	]);

	const getHelpButton = useCallback(() => {
		HELPBUTTON.render(<HelpButton />);
	}, []);

	useEffect(() => {
		Object.keys(appData.productBrowseData).forEach((key) => {
			if (key !== 'metaData' && key !== 'expiredCatalogs') {
				appData?.productBrowseData[key]?.relatedSchoolEvents?.length
					&& setSchoolStoreEvents([...appData.productBrowseData[key].relatedSchoolEvents])
				setServiceData(appData.productBrowseData[key]);
				appData.productBrowseData[key]?.productGroups &&
					Object.keys(appData.productBrowseData[key]?.productGroups).map(
						(catObj) => {
							setBreadcrupmData(
								appData.productBrowseData[key].productGroups[catObj]
							);
							setPromoMessages(appData.productBrowseData[key].promoMessages);
							setProductGroupProducts(appData?.productBrowseData[key]?.productGroups[catObj]?.products
								?.sort((a,b)=>a.orderSeq - b.orderSeq));
							return true;
						}
					);
					let hasJlry = false;
					let hasNationalCat = false;
					let hasCustomFilterJlry = false;
					appData?.productBrowseData[key]?.siteMapRefKeys?.map((refKey) => {
						refKey &&
							Object.keys(refKey).map((groupKey) => {
								if (
									ProductBrowseFiltersGroups?.ctaCheck?.siteMapincludeOsrs.includes(
										refKey[groupKey].catalogOsr
									) && !(ProductBrowseFiltersGroups?.ctaCheck?.siteMapExcludedNames?.includes(refKey[groupKey]?.catalogName))
								) {
									hasJlry = true;
								} else if(ProductBrowseFiltersGroups?.ctaCheck?.siteMapExcludedNames?.includes(refKey[groupKey]?.catalogName)) {
									hasCustomFilterJlry = true;
								} else {
									hasNationalCat = true;
								}
							});
					});
				let hasStandard = false;
				appData.productBrowseData[key]?.subCatalogRefKeys?.map((subObj)=>{
					ProductBrowseFiltersGroups?.ctaCheck?.excludedNames?.includes(subObj.catalogName) && (hasStandard = true)
				})
				let hasOrp = (appData.productBrowseData[key].subCatalogRefKeys?.length === 1 && !hasStandard) || appData.productBrowseData[key].subCatalogRefKeys?.length > 1
				let hasOnlyOrpCollection = appData.productBrowseData[key].subCatalogRefKeys?.length === 1 && appData.productBrowseData[key]?.subCatalogRefKeys[0]?.catalogName == "Official Ring Collection"
				let showFilterGroupTile = true;
				if ((hasJlry && !hasStandard && !hasOrp && !hasNationalCat) || (hasOnlyOrpCollection && !hasJlry && !hasStandard  && !hasNationalCat)) {
					showFilterGroupTile = false
				}
				setCatalogInfo({
					hasJlry,
					hasStandard,
					hasOrp,
					hasNationalCat,
					showFeatured:!hasJlry && hasStandard && !hasOrp,
					hasOnlyOrpCollection,
					showFilterGroupTile: showFilterGroupTile,
					isCanadaCollege,
					hasCustomFilterJlry
				});
			}
		});
	}, [appData.productBrowseData, ProductBrowseFiltersGroups]);

	useEffect(() => {
		getFilteredData();
		setPbData({
			products: removeDuplicates(isCanadaCollege
				? [...productGroupProducts, ...getNationalCatalogProducts]
				: [
					...getOrpCatalogProducts.onlyOrpProducts,
					...getOrpCatalogProducts.standaredCatalogProducts,
					...getOrpCatalogProducts.jlryCatalog,
					...getNationalCatalogProducts
				]
			),
		});
	}, [serviceData]);

	useEffect(() => {
		gaPageLoad();
	}, []);

	useEffect(() => {
		if(catalogInfo.hasOrp && !catalogInfo.hasNationalCat && !catalogInfo.hasStandard) {
			setFilterData({filter:appData.filterData?.orpFilter, isOrpFilter: true})
		} else {
			setFilterData({filter:appData.filterData?.nationalFilter, isOrpFilter: false})
			let filterOsrs = ProductBrowseFiltersGroups?.ctaCheck?.filterOsr;
			let groups = appData.filterData?.nationalFilter?.filterGroups?.filter(
				(groupObj) => filterOsrs.includes(groupObj.filterGroupOsr)
			);
			let filters = [];
			groups &&
				groups?.length &&
				groups.forEach((groupObj) => {
					groupObj.filters?.sort((a, b) => a.orderSequence - b.orderSequence);
					filters.push(...groupObj.filters);
				});
			setFilterGroupData([...filters]);
		}
	}, [appData.filterData,catalogInfo]);

	const getProducts = (groupObj, showORPCatGroups) => {
		groupObj?.filterName && gaPageLoad(groupObj.filterName);
		setTopFilterClicked(true)
		groupObj && setClickedFilterImageUrl(groupObj.imageUrl);
		if (
			groupObj.hasOwnProperty('showOrp') ||
			groupObj.hasOwnProperty('isOrp')
		) {
			if (groupObj.showOrp || groupObj.isOrp) {
				setPbData({ products: removeDuplicates([...getOrpCatalogProducts.orpProducts] )});
				setFilterData(catalogInfo.hasOrp? {filter:appData.filterData?.orpFilter,isOrpFilter:true} : {filter:appData.filterData?.nationalFilter,isOrpFilter:false});
			} else {
				setFilterData({filter:appData.filterData?.nationalFilter,isOrpFilter:false});
				setPbData({
					products: removeDuplicates(isCanadaCollege?[...productGroupProducts, ...getNationalCatalogProducts]:[...getOrpCatalogProducts.onlyOrpProducts, ...getOrpCatalogProducts.standaredCatalogProducts,...getOrpCatalogProducts.jlryCatalog, ...getNationalCatalogProducts]),
				});
			}
			setSelectedSubCatalog({});
		} else if(groupObj?.isCustomFilter) {
			let productsTemp = [];
			serviceData?.siteMapRefKeys?.forEach((siteMapObject) => {
				Object.keys(siteMapObject)?.forEach((key) => {
					if(siteMapObject[key]?.catalogName===groupObj?.filterName) {
						Object.keys(siteMapObject[key]?.productGroups)?.forEach((pgKey) => {
							productsTemp = [...productsTemp, ...siteMapObject[key]?.productGroups[pgKey]?.products]
						});
					}
				});					
			});
			productsTemp && productsTemp[0] && (productsTemp[0]['customFilterProduct'] = true);
			productsTemp && productsTemp[0] && (productsTemp[0]['filterNameToDisplay'] = groupObj?.filterName);
			setSelectedSubCatalog({});
			setPbData({ products: [...productsTemp] });
		} else {
			let filterArray = [];
			if (showORPCatGroups) {
				setSelectedSubCatalog(groupObj);
				if (groupObj.hasOwnProperty('catalogOsr')) {
					serviceData?.siteMapRefKeys?.length > 0 &&
						serviceData.siteMapRefKeys.forEach((refKey) => {
							refKey &&
								Object.keys(refKey).forEach((groupKey) => {
									if (refKey[groupKey].catalogOsr === groupObj.catalogOsr) {
										refKey[groupKey].productGroups &&
											Object.keys(refKey[groupKey].productGroups).forEach(
												(objKey) => {
													filterArray = [
														...refKey[groupKey].productGroups[objKey].products,
													];
												}
											);
									}
								});
						});
				} else {
					let subCatalog = serviceData?.subCatalogs[groupObj.catalogKey];
					filterArray = [
						...Object.values(subCatalog?.productGroups)[0].products,
					];
				}
			} else {
				filterArray = removeDuplicates(isCanadaCollege?[...productGroupProducts, ...getNationalCatalogProducts]:[...getOrpCatalogProducts.onlyOrpProducts, ...getOrpCatalogProducts.standaredCatalogProducts,...getOrpCatalogProducts.jlryCatalog, ...getNationalCatalogProducts]);
				setSelectedSubCatalog({});
			}
			setPbData({ products: [...filterArray] });
		}
		setSelectedGroup(groupObj);
	};

	const getOrpCatalogProducts = useMemo(() => {
		const excludedNames = ProductBrowseFiltersGroups?.ctaCheck?.excludedNames;
		let subCatProducts = [];
		let jlryCatalog = [];

		let standaredCatalog = serviceData?.subCatalogRefKeys?.find((filterObj) =>
			excludedNames?.includes(filterObj.catalogName)
		);

		let orpCatalogs = serviceData?.subCatalogRefKeys
			?.filter((filterObj) => !excludedNames?.includes(filterObj.catalogName))
			?.sort((a, b) => a.orderSequence - b.orderSequence);

		const orpCatalogKeys = (Object.keys(serviceData).length && orpCatalogs?.length && Object.keys(orpCatalogs)) || [];

		orpCatalogKeys.forEach((catKey) => {
				const productGroups = serviceData.subCatalogs[orpCatalogs[catKey].catalogKey]?.productGroups

				productGroups && Object.keys(productGroups).forEach((catObj) => {
						const catalogProducts = productGroups[catObj]?.products || []
						subCatProducts = [
							...subCatProducts,
							...catalogProducts
								.map((prodObj)=> ({...prodObj,isOrp: true}))
								.sort((a, b) => a.orderSeq - b.orderSeq),
						];
					});
			});

		serviceData?.siteMapRefKeys?.length > 0 &&
			serviceData.siteMapRefKeys.forEach((refKey) => {
				refKey &&
					Object.keys(refKey).forEach((groupKey) => {
						if (
							ProductBrowseFiltersGroups?.ctaCheck?.siteMapincludeOsrs.includes(
								refKey[groupKey].catalogOsr
							)
						) {
							refKey[groupKey].productGroups && Object.keys(refKey[groupKey].productGroups)
								.forEach((objKey) => {
										jlryCatalog = [
											...jlryCatalog,
											...refKey[groupKey].productGroups[objKey]?.products
												?.map((prodObj)=> ({...prodObj, isOrp: false}))
												?.sort((a, b) => a.orderSeq - b.orderSeq),
										];
									}
								);
						}
					});
			});
		let standaredCatalogProducts = []
		standaredCatalog &&
			serviceData.subCatalogs[standaredCatalog.catalogKey]?.productGroups &&
			Object.keys(
				serviceData.subCatalogs[standaredCatalog.catalogKey]?.productGroups
			).forEach((catObj) => {
				standaredCatalogProducts = [
					...serviceData.subCatalogs[standaredCatalog.catalogKey]?.productGroups[catObj]?.products
						?.map((prodObj)=> {return {...prodObj,isOrp: false}})
						.sort((a, b) => a.orderSeq - b.orderSeq),
				];
			});

		return {
			orpProducts: [...subCatProducts,...jlryCatalog, ...standaredCatalogProducts],
			subCatProducts: [...subCatProducts,...jlryCatalog],
			standaredCatalogProducts: standaredCatalogProducts,
			onlyOrpProducts: subCatProducts,
			jlryCatalog: jlryCatalog
		};
	}, [serviceData, ProductBrowseFiltersGroups]);

	const getNationalCatalogProducts = useMemo(() => {
		let nationalCatProducts = [];
		serviceData?.siteMapRefKeys?.length > 0 &&
			serviceData.siteMapRefKeys.forEach((refKey) => {
				refKey &&
					Object.keys(refKey).forEach((groupKey) => {
						if (
							!ProductBrowseFiltersGroups?.ctaCheck?.siteMapincludeOsrs.includes(
								refKey[groupKey].catalogOsr
							)
						) {
							refKey[groupKey].productGroups &&
								Object.keys(refKey[groupKey].productGroups).forEach(
									(objKey) => {
										nationalCatProducts = [
											...nationalCatProducts,
											...refKey[groupKey].productGroups[objKey]?.products?.map((prodObj)=> {return {...prodObj,isOrp: false}}),
										];
									}
								);
						}
					});
			});
		return nationalCatProducts
	}, [serviceData]);

	const getFilteredData = useCallback(()=>{
		let products = isCanadaCollege?[...productGroupProducts, ...getNationalCatalogProducts]:[
			...getOrpCatalogProducts.standaredCatalogProducts,
			...getNationalCatalogProducts
		]
		let filteredProducts = {};
		filterGroupData?.forEach((filterObj) => {
			filteredProducts[filterObj?.filterName] = [];
			 products?.forEach((productObj) =>{
				filterObj?.filterItems?.forEach((filter)=>{
					if(productObj?.productOsr?.startsWith(filter?.trim())){
						filteredProducts[filterObj?.filterName].push(productObj);
					}
				});
			});
		});
		let finalFilter = [];
		filterGroupData.forEach((filterObj)=>{ 
			if(filteredProducts[filterObj.filterName]?.length > 0 ) {
				filterObj.imageUrl = filteredProducts[filterObj.filterName][0]?.imageUrl;
				finalFilter.push(filterObj);
			}
		})
		setFilterGroupTilesData([...finalFilter])
	},[filterGroupData])

	const removeDuplicates = (arr=[]) => {
		const uniqueIds = [];
		!arr && (arr=[]);
		return arr.filter(element => {
			const isDuplicate = uniqueIds.includes(element.productOsr+"-"+element.displayName);
			if (!isDuplicate) {
				uniqueIds.push(element.productOsr+"-"+element.displayName);
				return true;
			}
			return false;
		});
	}

	return (
		<>
			{ColJlryBreadcrumb && getBreadcrumb()}
			{ColJlryTitleBar && getTitleBar()}
			{ProductBrowsePromoMsg && promoMessages?.length > 0 && getPromoMsg()}
			{catalogInfo.showFilterGroupTile && ProductBrowseFiltersGroups && filterGroupTiles()}
			{PaypalBannerData &&
				Object.keys(PaypalBannerData)?.length > 0 &&
				paypalBanner()}
			{catalogInfo.showFeatured &&
				ProductBrowseFeatured &&
				Object.keys(ProductBrowseFeatured)?.length > 0 &&
				featuredProducts()}
			{ProductBrowseProducts &&
				Object.keys(ProductBrowseProducts)?.length > 0 &&
				productTiles()}
			{getHelpButton()}
		</>
	);
};
