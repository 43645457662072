import React, { useState, useEffect, useMemo } from 'react'
import { LazyMotion, domAnimation } from "framer-motion"
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';
import { ProductSlider } from '../../components/Slider'
import { SLIDER } from '../../constants'
import { useGetFilterItemsList } from './hooks'
import { getFiltersByExistingProducts } from '../../util/getFiltersByExistingProducts';

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const HsJewelryFilterGroups = trackWindowScroll(React.memo(({
    altImage,
    filterData,
    pageContentFilterGroup,
    pbData,
    scrollPosition,
    selectedGroupData,
    setFilteredPbData,
    setFilteredQuickData,
}) => {
    const [groupData, setGroupData] = useState({})

    useEffect(() => {
        let selectedFilterGroup = filterData?.filterGroups?.find(groupObj => groupObj?.filterGroupOsr === pageContentFilterGroup?.ctaCheck?.filterOsr);
        const filterGroup = { ...selectedFilterGroup }
        if (pageContentFilterGroup?.ctaCheck?.IncludedFilters) {
            let includedFilters = pageContentFilterGroup?.ctaCheck?.IncludedFilters
            let filters = filterGroup?.filters ? [...filterGroup.filters] : [];
            if (includedFilters?.length) {
                includedFilters = includedFilters.split(',');
                filters = filters?.filter((obj) => includedFilters.indexOf(obj.filterOsr) !== -1)
                filters = filters?.sort((a, b) => includedFilters.indexOf(a.filterOsr) - includedFilters.indexOf(b.filterOsr))
            } else {
                filters = filters?.sort((a, b) => a.orderSequence - b.orderSequence)
            }
            filterGroup?.filters ? filterGroup.filters = [...filters] : undefined
        }
        setGroupData(filterGroup)
    }, [filterData, pageContentFilterGroup?.allowedFilterGroups])

    const setUnselectedFilterItems = () => {
        setFilteredQuickData(prevState => {
            const uncheckedFilters = prevState?.map(filterItem => ({
                ...filterItem,
                active: false,
                filters: filterItem?.filters?.map(currentFilter => {
                    return currentFilter.checked
                        ? { ...currentFilter, checked: false }
                        : currentFilter
                })
            }))

            return uncheckedFilters
        })
    }

    const setSelectedFilterItems = (filterArray, groupObj) => {
        selectedGroupData(groupObj)
        groupObj?.filterItems?.map((item) =>
            pbData.products?.map((prodObj) => {
                if (prodObj.productOsr === item) {
                    filterArray.push(prodObj)
                }
                return true
            })
        )
    }

    const handleError = (e) => e.target.src = altImage
    const handleGroupProducts = (groupObj) => {
        const filterArray = []
        if (groupObj === 'ALL') {
            setUnselectedFilterItems()
        } else {
            setSelectedFilterItems(filterArray, groupObj)
        }

        const filterItemsArray = groupObj === 'ALL' ? [...pbData.products] : filterArray
        setFilteredPbData({ "products": filterItemsArray })
        document.getElementById("jwlProductTiles")?.scrollIntoView({ behavior: 'smooth' })
    }

    const availableFilters = useMemo(() =>
        getFiltersByExistingProducts(groupData.filters, pbData.products),
        [groupData.filters, pbData.products]
    )

    const { filterItems, anchorFilterItem } = useGetFilterItemsList({
        filters: availableFilters,
        altImage,
        scrollPosition,
        handleFilterItemClick: handleGroupProducts,
        handleError,
        imageUrl: pageContentFilterGroup?.allGroupImageUrl?.imageUrl,
        displayName: pageContentFilterGroup?.allGroupImageUrl?.displayName
    })

    return groupData && Object.keys(groupData)?.length > 0 && (
        <LazyMotion features={domAnimation}>
            <LazyLoadComponent scrollPosition={scrollPosition}>
                <div className="product-categories">
                    <ProductSlider
                        className="container-fluid"
                        initial="hidden"
                        animate="show"
                        variants={SLIDER.CONTAINER}
                        settings={SLIDER.SETTINGS}
                        anchorItem={anchorFilterItem}
                        itemsList={filterItems}
                    />
                </div>
            </LazyLoadComponent>
        </LazyMotion>
    )
}))
