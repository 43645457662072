export const settings = {
    accessibility: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    dots: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false,
                arrows: true,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: false,
                arrows: true,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                dots: false,
                arrows: true,
            }
        }
    ]
}

export const container = {
    show: {
        transition: {
            staggerChildren: 0.2
        }
    }
};

export const productSettings = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            duration: 0.25,
        },
    },
};