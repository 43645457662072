import { useEffect, useState } from 'react'
import { getCustomerNumber } from '../util'

export const useGetCustomerNameListSettings = () => {
    const [loading, setLoading] = useState(true)
    const [customerNameListSettings, setCustomerNameListSettings] = useState()

    useEffect(() => {
        (async () => {
            const customerNumber = getCustomerNumber()
            try {
                const  url= `${window.location.origin}/apps/store/api/v1.1/customers/${customerNumber}/namelist-customer`
                const response = await fetch(url, { signal: AbortSignal.timeout(5000) })
                const data = await response.json();

                if (data?.length > 0) {
                    setCustomerNameListSettings(data)
                }
            } catch (error) {
                console.log(`Failed to get namelist settings for customer [${customerNumber}]: `, error)
            } finally {
                setLoading(false);
            }
        })()
    },[])

    return { customerNameListSettings, customerNameListSettingsLoading: loading } 
}