import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { AlertsList } from '../../components/Alerts/alertsList';
import { useGetCustomerNameListSettings } from '../../components/regalia-ux/hooks'
import {
	BREADCRUMB,
	HELPBUTTON,
	PAGETITLE,
	PAYPALBANNER,
	PRODUCTTILES,
	PROMOMSG
} from '../../constants/rootElements';
import { gaPageLoad } from "../../util/googleAnalytics";
import { Breadcrumb } from '../breadcrumb';
import { HelpButton } from '../help';
import { PaypalBanner } from '../paypalBanner';
import { TitleBar } from '../productBrowseTitle';
import { ColRegaliaProductTiles } from './colRegaliaProductTiles';

export const ColRegaliaPage = React.memo(({ appData }) => {
	const [pbData, setPbData] = useState({});
	const [catalogData, setCatalogData] = useState({});
	const [breadcrumbData, setBreadcrupmData] = useState([]);
	const [promoMessages, setPromoMessages] = useState([]);
	const cookies = new Cookies();
	const ColRegaliaBreadcrumb = Boolean(
		appData?.pagecontentData?.find((data) => data.contentType === 'Navigation')
	);
	const ColRegaliaTitleBar = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseTitle'
	);
	const ProductBrowseProducts = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseProducts'
	);
	const ProductBrowsePromoMsg = Boolean(
		appData?.pagecontentData?.find(
			(data) => data.contentType === 'ProductBrowsePromoMsg'
		)
	);
	const PaypalBannerData = appData?.pagecontentData?.find(
		(data) => data.contentType === 'PaypalId'
	);
	const ColRegaliaFilterTile = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseFiltersBar'
	);
	const customerHasSY2025UX = Object.keys(appData.productBrowseData)
		.filter(key => key !== 'metaData')
		.some(key => appData.productBrowseData[key].customerHasSY2025UX)

	const { customerNameListSettings } = useGetCustomerNameListSettings()
	const isStudentVerificationAvailable = customerNameListSettings && customerNameListSettings.length > 0
	
	const isRegaliaUx = customerHasSY2025UX || isStudentVerificationAvailable

	const getBreadcrumb = useCallback(() => {
		BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
	}, [breadcrumbData]);

	const getHelpButton = useCallback(() => {
        HELPBUTTON.render(<HelpButton />);
	},[]);

	const getTitleBar = useCallback(() => {
		PAGETITLE.render(
			<TitleBar
				pageContent={ColRegaliaTitleBar}
				affiliationData={appData?.affiliationData}
			/>
		);
	}, []);

	const productTiles = useCallback(() => {
		PRODUCTTILES.render(
			<ColRegaliaProductTiles
				pbData={pbData}
				pageContent={ProductBrowseProducts}
				filterData={appData.filterData}
				serviceData={catalogData}
				fieldToCompare={ColRegaliaFilterTile?.filedtocompare}
				isRegaliaUx={isRegaliaUx}
			/>
		);
		// eslint-disable-next-line
	}, [catalogData, isRegaliaUx]);

	const getPromoMsg = useCallback(() => {
		PROMOMSG.render(<AlertsList messages={promoMessages} />);
	}, [promoMessages]);

	const paypalBanner = useCallback(() => {
		PAYPALBANNER.render(<PaypalBanner paypalBannerData={PaypalBannerData} />);
	}, [PaypalBannerData]);
	useEffect(() => {
		Object.keys(appData.productBrowseData).map((key) => {
			if (key !== 'metaData') {
				appData.productBrowseData[key]?.productGroups &&
					Object.keys(appData.productBrowseData[key]?.productGroups).map(
						(catObj) => {
							setPbData(appData.productBrowseData[key].productGroups[catObj]);
							setPromoMessages(appData.productBrowseData[key].promoMessages);
							setCatalogData(appData.productBrowseData[key]);
							setBreadcrupmData(
								appData.productBrowseData[key].productGroups[catObj]
							);
							return true;
						}
					);
			}
			return true;
		});
	}, [appData.productBrowseData]);

	useEffect(()=>{		  
		if(appData.productBrowseData && Object.keys(appData.productBrowseData).length>0){
			gaPageLoad();
		}
		/* TODO: Switch to new SVP api
		let customerNumber = appData?.affiliationData?.affiliateId;
        let custName = appData?.affiliationData?.name;
        const checkCustomerUrl = `${process.env.REACT_APP_API_PATH}/catalog/v1.1/customers/${customerNumber}/rsv/`;
            axios.get(checkCustomerUrl)
            .then(result => {    
                if(result?.data?.option === "REQUIRED" || result?.data?.option === "OPTIONAL"){
                    if(cookies.get('studentId')!==undefined && cookies.get('studentId') != null && cookies.get('studentId') !=''){
						const url = `${process.env.REACT_APP_API_PATH}/catalog/v1.1/customers/${customerNumber}`+'/students/'+cookies.get('studentId');
						axios.get(url)
						.then(result => { 
						}).catch(error => {
							if(custName!=''){
								let updatedCustName = custName.replace(/\s+/g,'-').toUpperCase();
								window.location.href=`${process.env.REACT_APP_BASE_PATH}/apps/store/customer/${customerNumber}/${updatedCustName}/#regalia-student-verification`;
							}
						});
					}else{
						if(custName!=''){
							let updatedCustName = custName.replace(/\s+/g,'-').toUpperCase();
							window.location.href=`${process.env.REACT_APP_BASE_PATH}/apps/store/customer/${customerNumber}/${updatedCustName}/#regalia-student-verification`;
						}
					}
                }
			}).catch(error => {})
		*/
	},[])

	return (
		<>
			{ColRegaliaTitleBar &&
				Object.keys(ColRegaliaTitleBar)?.length > 0 &&
				getTitleBar()}
			{ProductBrowsePromoMsg && promoMessages?.length > 0 && getPromoMsg()}
			{ColRegaliaBreadcrumb && getBreadcrumb()}
			{ProductBrowseProducts &&
				Object.keys(ProductBrowseProducts)?.length > 0 &&
				productTiles()
				}
			{
            getHelpButton()
        	}
			{PaypalBannerData &&
				Object.keys(PaypalBannerData)?.length > 0 &&
				paypalBanner()}
		</>
	);
});
