import React, { useEffect, useState } from 'react'
// import ReactDOM from 'react-dom';
import { ProductBrowseTiles } from './productBrowseTiles'

export const HomePage = props => {
	const { appData } = props
	const [ pbData, setPbData ] = useState({})

	const ProductBrowseTitleBar = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseTitleBar")
	const ProductBrowseProducts = appData?.pagecontentData?.find(data => data.contentType === "ProductBrowseProducts")
	
	useEffect(()=> {
		Object.keys(appData.productBrowseData)
			.filter((key) => key !== 'metaData')
			.map((key) => setPbData(appData.productBrowseData[key])
		)
	},[appData.productBrowseData])

	return (
		<>
			{ProductBrowseTitleBar && 
				<div className="product-bc-name p-2">
					<div>
						<ol className="breadcrumbs">
							{
								pbData?.homewardPathSegments?.length > 0 &&
								pbData?.homewardPathSegments.map((breadcrumps, index) =>
									<li key={index}>{breadcrumps.url ? <a href={breadcrumps.url} className="text-underline">{breadcrumps.displayName}</a> : breadcrumps.displayName}</li>
								)
							}
						</ol>
					</div>


					<div className="row mx-0 justify-content-between">
						<div className="h4">{pbData?.displayName}</div>
						<div className="product-results-div">
							<p className="product-results">Showing {pbData?.products?.length} Results</p>
						</div>
					</div>
					
				</div>
			}

			{
				ProductBrowseProducts && <ProductBrowseTiles pbData={pbData} fromProchamp={true} pageContent={ProductBrowseProducts}/>
			}
			
		</>
	)
}
