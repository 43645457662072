import React, { useCallback, useEffect, useState } from 'react';
import { AlertsList } from '../../components/Alerts/alertsList';
import {
	BREADCRUMB,
	HELPBUTTON,
	PAGETITLE,
	PAYPALBANNER,
	PRODUCTTILES,
	PROMOMSG
} from '../../constants/rootElements';
import { gaPageLoad } from '../../util/googleAnalytics';
import { Breadcrumb } from '../breadcrumb';
import { HelpButton } from '../help';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { PaypalBanner } from '../paypalBanner';
import { TitleBar } from '../productBrowseTitle';

export const RegaliaMultiGroupPage = ({ appData }) => {
	const [productGroups, setProductGroups] = useState({});
	const [breadcrumbData, setBreadcrupmData] = useState([]);
	const [promoMessages, setPromoMessages] = useState([]);

	const RegaliaBreadcrumb = Boolean(
		appData?.pagecontentData?.find((data) => data.contentType === 'Navigation')
	);
	const RegaliaTitleBar = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseTitle'
	);
	const ProductBrowseProducts = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseProducts'
	);
	const ProductBrowsePromoMsg = Boolean(
		appData?.pagecontentData?.find(
			(data) => data.contentType === 'ProductBrowsePromoMsg'
		)
	);
	const PaypalBannerData = appData?.pagecontentData?.find(
		(data) => data.contentType === 'PaypalId'
	);
	const RegaliaFilterTile = appData?.pagecontentData?.find(
		(data) => data.contentType === 'ProductBrowseFiltersBar'
	);

	const getBreadcrumb = useCallback(() => {
		BREADCRUMB.render(<Breadcrumb pbData={breadcrumbData} />);
	}, [breadcrumbData]);

	const getPromoMsg = useCallback(() => {
		PROMOMSG.render(<AlertsList messages={promoMessages} />);
	}, [promoMessages]);

	const getTitleBar = useCallback(() => {
		PAGETITLE.render(
			<TitleBar
				pageContent={RegaliaTitleBar}
				affiliationData={appData?.affiliationData}
			/>
		);
	}, []);

	const productTiles = useCallback(() => {
		PRODUCTTILES.render(
			<div>
				{Object.keys(productGroups || {}).map(productGroupKey => (
					<ProductBrowseTiles
						key={productGroupKey}
						pbData={{ "products": productGroups[productGroupKey].products || [] }}
						pageContent={{
							title: productGroups[productGroupKey].displayName
						}}
						productInfo={{"prodDescDotCms": false, "performSort": false }}
						isSliderView={true}
					/>
				))}
			</div>
		);
		// eslint-disable-next-line
	}, [productGroups]);

	const getHelpButton = useCallback(() => {
        HELPBUTTON.render(<HelpButton />);
  	},[])

	const paypalBanner = useCallback(() => {
		PAYPALBANNER.render(<PaypalBanner paypalBannerData={PaypalBannerData} />);
    }, [PaypalBannerData]);
    
    useEffect(()=>{
		gaPageLoad();
    },[])

	useEffect(() => {
		Object.keys(appData.productBrowseData).map((key) => {
			const mainCatalog = appData.productBrowseData[key]
			if ((key !== 'metaData') && (mainCatalog?.catalogOsr === 'col_fq') && (mainCatalog?.subCatalogs)) {
				const productGroups = Object.values(mainCatalog.subCatalogs)
					.flatMap(subCatalog => subCatalog.productGroups)
					.reduce((acc, subCatalogProductGroups) => 
						acc = { ...acc, ...subCatalogProductGroups }
					, {})
				setProductGroups(productGroups);
				setPromoMessages(appData.productBrowseData[key].promoMessages);
				appData.productBrowseData[key]?.productGroups &&
					Object.keys(appData.productBrowseData[key]?.productGroups).map(
						(catObj) => {
							setBreadcrupmData(appData.productBrowseData[key].productGroups[catObj]);
							return true;
						}
					);
			}
			return true;
		});
	}, [appData.productBrowseData]);

	return (
		<>
			{RegaliaTitleBar && Object.keys(RegaliaTitleBar)?.length > 0 && getTitleBar()}
			{ProductBrowsePromoMsg && promoMessages?.length > 0 && getPromoMsg()}
			{RegaliaBreadcrumb && getBreadcrumb()}
			{ProductBrowseProducts &&
				Object.keys(ProductBrowseProducts)?.length > 0 &&
				productTiles()}
			{
				getHelpButton()
			}
			{PaypalBannerData &&
				Object.keys(PaypalBannerData)?.length > 0 &&
				paypalBanner()}
		</>
	);
};
