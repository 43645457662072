import { Spinner, Background } from '../../basic/loader'
import { useGetConsumerDataById } from '../hooks'
import { redirectWithHashtagParams } from '../util'

export const RegaliaUxStudentConfirmationStep = ({consumerSelection, setCatalogSelection, catalogActionUrl, consumerType, consumerId, backButtonHandler }) => {
    const {consumerData, consumerDataLoading} = useGetConsumerDataById(consumerId)

    const mismatchConsumerType = consumerData && consumerData.type !== consumerType
    const isStudentConfirmationStepVisible = !consumerDataLoading && consumerData && !mismatchConsumerType
    const isUnknownStudentStepVisible = !consumerDataLoading && (!consumerData || mismatchConsumerType)

    const confirmButtonHandler = () => {
        const params = {catalogOsr: consumerData?.catalogOsr?.toLowerCase(), publishedCatalogId: consumerData?.publishedCatalogId, degree:consumerData?.degree}

        if (consumerSelection.catalogOsr==="col_greg_std_pkg") {
            params.catalogOsr = consumerSelection.catalogOsr
            params.publishedCatalogId = consumerData.packageId
        }


        if (catalogActionUrl) {
            redirectWithHashtagParams(catalogActionUrl, params)
        } else {
            const { catalogOsr, publishedCatalogId } = params
            setCatalogSelection(catalogOsr, publishedCatalogId)
        }
        
    }
   
    return (
        <>
            {consumerDataLoading && <Background><Spinner /></Background>}
            {isStudentConfirmationStepVisible && 
                <div className="student-confirmation-step">
                    <h3 className="ml-4">Id Confirmation</h3>
                    <p className="ml-4">Please confirm this information is correct</p>
                    <div className="btn-toolbar ml-4">
                        <div className="w-50">
                            <p>Name</p>
                            <p className="lead mb-5">{consumerData?.fullName ? consumerData?.fullName:"Not available"}</p>
                            {consumerData?.degree && 
                                <><p>Degree</p>
                                <p className="lead mb-5">{consumerData?.degree}</p>
                            </>}
                        </div>
                        <div className="w-50">
                                <p>Entered ID</p>
                                <p className="lead mb-5">{consumerData?.id}</p>
                            {consumerData?.fieldOfStudy && 
                            <><p>Field of Study</p>
                                <p className="lead mb-5">{consumerData?.fieldOfStudy}</p>
                            </>}
                        </div>
                        <div className="btn-toolbar w-100">
                            <div className="w-50">
                                <button className="btn btn-primary btn-primary--ghost back" onClick={backButtonHandler}>Back</button>   
                            </div>
                            <div className="w-50">
                                <button className="btn btn-primary" onClick={confirmButtonHandler}>Confirm</button>   
                            </div>
                        </div>
                    </div>
                    <p className="font-weight-bold mb-0 mt-4 ml-4">Incorrect ID?</p>
                    <p className="ml-4">Please contact your school for help</p>
                </div>
            }
            {isUnknownStudentStepVisible &&
                <div className="unknown-student-step">
                    <h3 className="ml-4">Id Confirmation</h3>
                    <p className="ml-4">Unable to confirm your ID. <br/>Please contact your school for help</p>
                    <button className="btn btn-primary btn-primary--ghost back" onClick={backButtonHandler}>Back</button>   
                </div>
            }
        </>
    )
}