import { errorLogger } from '../util' 
// Mock data for local development
// import MockPageContentData from './pb-page-content-data.json'
// import MockProductBrowseData from './pb-service-data.json'
// import MockAffiliationData from './affiliation-data.json'
// import MockFilterData from './pb-filter-data.json'
// import MockPromoCodesData from  './pb-promo-codes-data.json'
// import MockExcludeListData from './pb-exclude-list.json'

const pageContentData = window.pageContentData || window.pageContextData || window.pageContent || window.hsJwlPageContent || window.hsGradPageContent || window.hsJktsPageContent
const productBrowseData = window.productBrowseData || window.serviceData || window.hsJwlProductBrowseData || window.hsGradProductBrowseData || window.hsJktsProductBrowseData
const filterData = window.filterData || window.hsJwlFilterData || window.hsGradFilterData

// test if all mandatory data is available
if (!pageContentData || !productBrowseData) {
    errorLogger('Required data is not available on the page.', 'required-data-in-not-available')
}

let AppData = {
    affiliationData: window.affiliationData ?? {},
    productBrowseData: productBrowseData ?? {},
    pagecontentData: pageContentData ?? [],
    filterData: filterData ?? {},
    PromoCodesData: window.promocodes ?? [],
    excludeListData: window.promoCodesExcludeList ?? [],
}

if (window.location.hostname === "localhost") {
    try {
        AppData = {
            affiliationData: MockAffiliationData ?? {},
            productBrowseData: MockProductBrowseData ?? {},
            pagecontentData: MockPageContentData ?? [],
            filterData: MockFilterData ?? {},
            PromoCodesData: MockPromoCodesData ?? [],
            excludeListData: MockExcludeListData ?? [],
        }
    } catch (error) {
        console.error('Mock data is not defined:', error)
    }
}

export { AppData }