import axios from "axios";

export const BASE_URL = "https://devservices-cache.jostens.com/api/cacheService/productdetail/3040893/20210405133002943116/20210405133002963116";

export const fetchData = async () => {
  try {
    return await axios.get(BASE_URL);
  } catch (e) {
    return [];
  }
};

export const notNullCheck = (value) => {
  if (value <= 0 || value == '0' || value == '.00' || value == '0.00')
    return false;
  if (value) {
    return true;
  }
};

export const decimalFormat = (amount) => {
  return Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const getDotCmsPromoCode = (productObj, promoCodes) => promoCodes?.find(o => productObj.prodRefCode == o.prodRefCode);

export const promoMessageConverter = ({
  discountedMinPrice,
  dotCMSPromo,
  isCustomerInExcludeList,
  isHSJewelry,
  promoCode,
  promoText
}) => {
  if(isCustomerInExcludeList) return undefined

  const isDiscountPriceCorrect = notNullCheck(discountedMinPrice)
  const isCommonPromoCodeAvailable = isDiscountPriceCorrect && promoCode
  const message = `${promoText ?? ''} ${promoCode}`

  // For HS jewelry promo code from DB has priority
  if(isHSJewelry && isCommonPromoCodeAvailable) return message

  if(dotCMSPromo) return dotCMSPromo
  if(isCommonPromoCodeAvailable) return message

  return undefined
}

export const promoDataConverter = ({
  productObj,
  promoCodes,
  fromHsJwl,
  isCustomerInExcludeList = false,
  schoolStoreEvents = []
}) => {
  let dotCMSPromo = getDotCmsPromoCode(productObj, promoCodes)
  const JNETtPromoEvents = ['Order Day', 'Class Meeting', 'Add Order Event', 'Delivery']
  const isJnetEventActive = schoolStoreEvents.some(
    event => JNETtPromoEvents.includes(event.environment) && event?.productLines?.includes('JLRY')
  );

  if (!fromHsJwl && dotCMSPromo) {
    productObj.promoText = dotCMSPromo.promoText
  }

  productObj.messageWithPromoCode = promoMessageConverter({
    promoCode: productObj?.promoCode,
    promoText: productObj?.promoText,
    dotCMSPromo: isJnetEventActive ? undefined : dotCMSPromo?.promoText,
    discountedMinPrice: productObj?.discountedMinPrice,
    isHSJewelry: fromHsJwl,
    isCustomerInExcludeList
  })
}