import { RegaliaUxConstants } from '../constants'
import { RegaliaUxCtaButtons } from './RegaliaUxCtaButtons'
import { getCustomerNumber } from '../util'

export const RegaliaUxConsumerTypeStep = ({ stepsData, onClick }) => {

    const data = stepsData && stepsData[RegaliaUxConstants.CONSUMER_TYPE_STEP].map(catalogOsr => {
        let label = ''
        if (RegaliaUxConstants.STUDENT_CATALOG_OSR_LIST.includes(catalogOsr)) {
            label = 'Student'
        }
        if (RegaliaUxConstants.FACULTY_CATALOG_OSR_LIST.includes(catalogOsr)) {
            label = 'Faculty'
        }
        return {label: label, value: catalogOsr}
    })

    const customerNumber = getCustomerNumber()
    const backToSchoolStoreUrl = `/apps/jcom/router.mvc${customerNumber ? ('?cn='+customerNumber) : ''}`

    return (
        <>
            <h4 className="text-uppercase font-weight-bold">{RegaliaUxConstants.DEFAULT_DIALOG_TITLE}</h4>
            <p className="lead small">{RegaliaUxConstants.DEFAULT_DIALOG_SUBTITLE}</p>
            <RegaliaUxCtaButtons data={data} onClick={onClick} singleColumn={true} />
            <a className="font-weight-bold" href={backToSchoolStoreUrl}>Back To School Store</a>
        </>
    )
}