import React, { useCallback, useEffect, useState } from 'react';
import { ProductBrowseTiles } from '../home/productBrowseTiles';
import { ProductFilterTile } from '../hs-jwl/productFilterTile';
import Select from 'react-select';
import { selectdropdown } from "../../util/googleAnalytics";
import { RegaliaUxDialog } from '../../components/regalia-ux';
import { useGetRegaliaUxDialogData } from '../../hooks/useGetRegaliaUxDialogData';
import { filterProducts } from '../../util/filterProducts';

export const ColRegaliaProductTiles = React.memo((props) => {
	const { serviceData, filterData, pageContent, fieldToCompare, isRegaliaUx } = props;
	
	const [filteredPbData, setFilteredPbData] = useState({});
	const [filteredItems, setFilteredQuickData] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [showDropDown, setShowDropDown] = useState(false);
	const [productData, setProductData] = useState({ products: [] });
	const [subCatProductData, setSubCatProductData] = useState({});
	const [selectedOption, setSelectedOption] = useState({});
	const [catalogOptions, setCatalogOption] = useState([]);
	const [subCatFilterData, setSubCatFilterData] = useState({});
	const [isRegaliaDialogVisible, setIsRegaliaDialogVisible] = useState(true);
	
	const searchParamsDegree = new URLSearchParams(decodeURIComponent(window.location.search))?.get('degree');
	const [searchParamsDisabled, setSearchParamsDisabled] = useState(
		new URLSearchParams(decodeURIComponent(window.location.search))?.get('subCatalogDisabled')
	);

	const regaliaUxDialogData = useGetRegaliaUxDialogData(serviceData, filterData, productData?.products);

	useEffect(() => {
		const allProducts = productData?.products || [];
		let products = [];

		if(allProducts.length > 1 && Object.keys(filteredPbData)?.length > 0) {
			products = [...filteredPbData.products];
		} else {
			products = [...allProducts.sort((a, b) => a.orderSeq - b.orderSeq)];
		}
		
		setSubCatProductData({products});
	}, [productData, filteredPbData]);

	useEffect(() => {
		if (serviceData?.subCatalogRefKeys?.length > 0) {
			const options = serviceData.subCatalogRefKeys.map(subCatalog => ({
				value: subCatalog.catalogKey,
				label: subCatalog.catalogName
			}));
			setShowDropDown(options.length > 1);
			setCatalogOption(options);
			if (searchParamsDegree) {
				setSelectedOption(options.find(o => o.value.includes(searchParamsDegree)));
			} else if (options.length === 1) {
				setSelectedOption(options[0]);
			}
			setSearchParamsDisabled(searchParamsDisabled === "true");
		} else if (serviceData?.productGroups) {
			Object.keys(serviceData.productGroups).forEach(optionKey => {
				setProductData({ products: serviceData.productGroups[optionKey].products });
				setSubCatFilterData(serviceData.productGroups[optionKey].products.length > 6 ? filterData : {});
			});
		}
	}, [serviceData]);

	useEffect(() => {
		if (selectedOption && Object.keys(selectedOption)?.length > 0) {
			selectdropdown(selectedOption?.label);

			const subCatalogs = Object.entries(serviceData?.subCatalogs || {});
			const selectedSubCatalog = subCatalogs.find(([key]) => key.includes(selectedOption?.value))?.[1];
			const currentProducts = selectedSubCatalog?.productGroups
				? Object.values(selectedSubCatalog.productGroups).flatMap(group => group.products)
				: [];

			setProductData({ products: currentProducts });

			if (currentProducts.length > 6) {
				let catalogFilters = { ...filterData };

				filterData?.filterGroups?.forEach((filterObjs, index) => {
					let filter = filterObjs.filters?.filter((filterObj) => {
						return currentProducts.some((productObj) => filterObj.filterItems.includes(productObj.productOsr));
					});

					catalogFilters.filterGroups[index].filters = [...filter];
				});
				
				setSubCatFilterData(catalogFilters);
			} else {
				setSubCatFilterData({});
			}
		}
	}, [selectedOption]);

	const handleCatalogSelection = useCallback((val) => {
		setSelectedOption(val);

		const catalogOsr = val?.value.split("_")[0];

		if (catalogOsr) {
			const filteredOptions = catalogOptions.filter(option => option.value.includes(catalogOsr));
			setCatalogOption(filteredOptions);
		}
	}, [catalogOptions]);


	if (serviceData) {

		return (
			<>
				{showDropDown && (
					<>
						<div className="default-text">
							Select a collection from the drop down below to see results.
						</div>
						<div className="row container-fluid mb-3">
							<div
								className={
									`col-lg-4 col-md-6 ${selectedOption && Object.keys(selectedOption)?.length > 0 ? "" : "highlight-border"}`
								}
							>
								<Select
									value={
										catalogOptions.find(
											option => option.value.includes(selectedOption.value)
										) || { label: "Select A Collection", value: "" }
									}
									onChange={setSelectedOption}
									options={catalogOptions}
									isDisabled={searchParamsDisabled}
								/>
							</div>
						</div>
					</>
				)}

				{isRegaliaUx && <RegaliaUxDialog
					isVisible={isRegaliaDialogVisible}
					data={regaliaUxDialogData}
					onHide={() => setIsRegaliaDialogVisible(false)}
					onSelectCatalog={handleCatalogSelection}
					onSelectDegree={(filterList) => {
						setFilteredQuickData(filterList);
						const filteredProducts = filterProducts(
							filterList,
							productData?.products,
							false,
							fieldToCompare
						);
						setFilteredPbData({ products: filteredProducts });
						setIsRegaliaDialogVisible(false);
					}}
				/>}

				{selectedOption?.value && (
					<>
						{productData?.products?.length > 1 && (
							<ProductFilterTile
								pbData={productData}
								filteredPbData={filteredPbData}
								filterData={subCatFilterData}
								setFilteredPbData={setFilteredPbData}
								selectedGroup={selectedGroup}
								setFilteredQuickData={setFilteredQuickData}
								filteredItems={filteredItems}
								setSelectedGroup={setSelectedGroup}
								fieldToCompare={fieldToCompare}
							/>
						)}

						<ProductBrowseTiles
							pbData={subCatProductData}
							pageContent={{
								...pageContent,
								title: `${selectedOption?.label ? selectedOption?.label : ""} ${pageContent?.title}`,
							}}
							productInfo={{
								prodDescDotCms: false,
								checkCtaKey: "onlineOrderableFlag",
								performSort: false,
							}}
							isColRegalia={true}
						/>
					</>
				)}
			</>
		);
	} else {
		return null;
	}
});
